import fetch from '@/client/api/Fetcher';
import { GetUrlParams } from '@/client/hooks/GetUrlParams';
import { IsOutsideElement } from '@/client/hooks/IsOutsideElement';
import { justValidate } from '@/client/lib/JustValidate';
import { EmailRule } from '@/client/lib/JustValidate/rules/Email';
import { RequiredRule } from '@/client/lib/JustValidate/rules/Required';
import { toast } from '@/client/plugins/@toaster';
import { serialize } from '@shoelace-style/shoelace';
import Alpine from 'alpinejs';

enum StatusCode {
	Unauthorized = 401,
	UnprocessableEntity = 422,
	TooManyRequests = 429,
}

const messages: Record<StatusCode, StatusMessage> = {
	[StatusCode.Unauthorized]: {
		title: 'Accès non autorisé !',
		description: 'Saisissez un e-mail et un mot de passe valides !',
		type: 'warning',
	},
	[StatusCode.UnprocessableEntity]: {
		title: 'Compte inactif !',
		description: "Vous pouvez l'activer en cliquant sur le lien envoyé par e-mail.",
		type: 'default',
	},
	[StatusCode.TooManyRequests]: {
		title: 'Trop de tentatives de connexion !',
		description: 'Veuillez réessayer plus tard.',
		type: 'info',
	},
};

Alpine.data('Login', () => ({
	url: '/post/connexion',
	validator: null,
	loading: false,
	fields: {
		username: "[name='username']",
		password: "[name='password']",
	},
	email: '',
	remember: false,
	init() {
		const { container, form, checkbox } = this.$refs;
		this.container = container;
		this.form = form;
		this.checkbox = checkbox;

		IsOutsideElement(this.container, () => {
			this.validator.clearErrors();
			this.form.reset();
		});

		const { username, password } = this.fields;
		this.validator = justValidate(this.form)
			.addField(username, EmailRule)
			.addField(password, RequiredRule)
			.onSuccess((event: SubmitEvent) => {
				event.preventDefault();

				localStorage.setItem('email', this.email);

				this.submit();
			});

		const storedEmail = localStorage.getItem('email');
		if (storedEmail) {
			this.email = storedEmail;
			this.remember = true;
		}
	},
	async submit() {
		this.loading = true;
		await this.$nextTick();

		try {
			const { username, password } = serialize(this.form);
			await fetch.Post(this.url, {
				username,
				password,
			});

			document.location.href = GetUrlParams('callback') || '/mon-compte';
		} catch (error) {
			if (env === 'development' || env === 'preview') {
				console.error(error);
			}

			const { status } = error.response;
			const title = messages[status as StatusCode].title;
			const description = messages[status as StatusCode].description;
			const type = messages[status as StatusCode].type;

			toast(title || 'Une erreur est survenue !', {
				description: description || 'Veuillez réessayer plus tard.',
				type: type || 'danger',
			});
		} finally {
			this.loading = false;
		}
	},
	rememberMe() {
		if (this.remember) {
			localStorage.setItem('email', this.email);
		} else {
			localStorage.removeItem('email');
		}
	},
}));

if (module.hot) {
	module.hot.accept();
	module.hot.dispose((): void => {
		console.clear();
		window.location.reload();
	});
}
